export const NoEventsRow = () => (
  <tr>
    <td
      colSpan={8}
      className="px-3 py-6 w-full text-xs font-medium text-gray-800"
    >
      <div className="flex justify-center loaderWrap">No Results Found</div>
    </td>
  </tr>
);
